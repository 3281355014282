import { styled } from '@mui/material/styles'
import { FilledInput } from '@mui/material'

const DefaultInput = styled(FilledInput)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius / 2,
	overflow: 'hidden',

	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 0),
	},
}))

export { DefaultInput }
