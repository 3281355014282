import React from 'react'

import { Box, Typography } from '@mui/material'

const Hero = ({ image, title, subTitle }) => {
	return (
		<Box
			component={'section'}
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				py: 4,
			}}
		>
			{image ? (
				<Box
					sx={{
						width: theme => ({
							xs: '100%',
							sm: theme.spacing(8 * 6),
							md: theme.spacing(8 * 10),
						}),
					}}
				>
					<img alt={'hero'} src={image} style={{ width: '100%' }} />
				</Box>
			) : null}
			<Typography color={'textPrimary'} gutterBottom variant={'h3'}>
				{title}
			</Typography>
			<Typography color={'textPrimary'} variant={'h5'}>
				{subTitle}
			</Typography>
		</Box>
	)
}

export default Hero
