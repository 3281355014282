import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { animated, config, useSpring } from 'react-spring'
import { debounceTime } from 'rxjs/operators'
import { useObservableState } from 'observable-hooks'
import axios from 'axios'

import {
	Box,
	Container,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
} from '@mui/material'

import { fullSearch } from '../api'
import { SearchIcon } from '../icons'
import { Hero } from '../components/hero'
import { DefaultLayout as Layout } from '../layouts'
import { DefaultInput } from '../components/inputs'
import { ListItemSkeleton } from '../components/skeletons'
import Block from '../components/Block'
import Link from '../components/Link'

//import { superSupplier as responseData } from '../mock-data'

const cancellableFullSearch = async ({ queryKey }) => {
	const source = axios.CancelToken.source()
	const promise = fullSearch(queryKey[1])
	promise.cancel = () => source.cancel()
	return promise
}

const createProfileLink = ({ custaccountTinyMCE }) =>
	`/suppliers/${custaccountTinyMCE}`

const useResults = query =>
	useQuery(['fullSearch', { query }], cancellableFullSearch, {
		enabled: Boolean(query),
	})

const SourcingPage = () => {
	const [value, setValue] = useState('')
	const [query, onValueChange] = useObservableState(
		value$ => value$.pipe(debounceTime(250)),
		''
	)
	const { data: { results = [] } = {}, isLoading } = useResults(query)
	const fadeStyles = useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
		delay: 200,
		config: config.gentle,
	})
	const translateStyles = useSpring({
		from: { opacity: 0, x: 0 },
		to: { opacity: 1, x: 1 },
		delay: 500,
		config: config.stiff,
	})

	const handleChange = event => {
		setValue(event.target.value)
		onValueChange(event.target.value)
	}

	return (
		<Layout>
			<Block>
				<Container>
					<Box pt={12}>
						<Hero
							align={'center'}
							title={
								<center>
									<animated.div
										style={{
											display: 'inline-block',
											...fadeStyles,
										}}
									>
										Find B2B suppliers.
									</animated.div>{' '}
									<animated.div
										style={{
											display: 'inline-block',
											...translateStyles,
											x: translateStyles.x.to({
												range: [0, 1],
												output: [150, 0],
											}),
											skew: translateStyles.x
												.to({
													range: [0, 0.5, 1],
													output: [-30, -30, 0],
												})
												.to(x => `${x}deg`),
										}}
									>
										Fast.
									</animated.div>
								</center>
							}
						/>
					</Box>

					<animated.div style={fadeStyles}>
						<Box
							display={'flex'}
							justifyContent={'center'}
							pb={4}
							px={2}
						>
							<DefaultInput
								onChange={handleChange}
								placeholder={
									'Search suppliers, branches, products'
								}
								startAdornment={
									<InputAdornment position={'start'}>
										<SearchIcon />
									</InputAdornment>
								}
								value={value}
								variant={'filled'}
								sx={{
									borderRadius: 100,
									height: theme => theme.spacing(7),
									maxWidth: '100%',
									width: theme => theme.spacing(50),
								}}
							/>
						</Box>
					</animated.div>

					{isLoading ? (
						<List>
							<ListItemSkeleton />
							<ListItemSkeleton />
							<ListItemSkeleton />
						</List>
					) : (
						<List>
							{results.map(result => (
								<ListItem key={result['custaccountTinyMCE']}>
									<ListItemText
										primary={
											<Box fontWeight={500}>
												<Link
													to={createProfileLink(
														result
													)}
												>
													{result.descr}
												</Link>
											</Box>
										}
										secondary={result.reason}
									/>
								</ListItem>
							))}
						</List>
					)}
				</Container>
			</Block>
		</Layout>
	)
}

export default SourcingPage
